import React, { useCallback, useEffect, useState } from "react";
import { CandidatePoolGrid } from "../../../Ag Grid/CandidatePoolGrid";

export const EmpScheduleInterviewData = ({
  setPage,
  jdData,
  setInviteProcess,
  search,
  filterData,
  tableRows,
}) => {
  console.log(tableRows);
  const [tableData, setTableData] = useState([]);

  const [colDefs] = useState([
    { field: "JD ID", flex: 2 },
    { field: "No of Reqs", flex: 1 },
    { field: "Open Reqs",  flex: 1 },
    { field: "First Round", flex: 1 },
    { field: "Second Round", flex: 1 },
    { field: "Third Round", flex: 1 },
    { field: "HR Round", flex: 1 },
    { field: "Details", flex: 1, cellRenderer: ViewDetail },
  ]);

  const transformData = (data) => {
    return data?.map((row, index) => ({
      key: index,
      "JD ID": row?.jdId?.toUpperCase(),
      "No of Reqs": row?.numOfReqs ? row?.numOfReqs : "...",
      "Open Reqs": row?.openReqs ? row?.openReqs : "...",
      "First Round": row?.firstStage ? row?.firstStage : "0",
      "Second Round": row?.secondStage ? row?.secondStage : "0",
      "Third Round": row?.thirdStage ? row?.thirdStage : "0",
      "HR Round": "...",
      extraInfo: { setInviteProcess, setPage, row, jdData },
    }));
  };

  let dataToDisplay = tableRows;
  useEffect(() => {
    dataToDisplay = search ? filterData : tableRows;
    const transformedData = transformData(dataToDisplay);
    setTableData(transformedData);
  }, [search, filterData, tableRows]);

  const onGridReady = useCallback((params) => {
    setTableData(tableRows);
  }, []);
  return (
    <>
      <CandidatePoolGrid
        colDefs={colDefs}
        tableData={tableData}
        onGridReady={onGridReady}
      />
    </>
  );
};

export const ViewDetail = (props) => {
  const { row, setPage, setInviteProcess, jdData } =
    props?.data?.extraInfo || {};
  return (
    <button
      className="btn"
      onClick={() =>
        setPage({
          index: 2,
          jdId: row?.jdId,
          row: row,
          jdFile: jdData?.find((item) => item.jdId === row.jdId)?.jdFile,
          setInviteProcess: setInviteProcess,
        })
      }
    >
      View Details
    </button>
  );
};
