import React, { useEffect, useState, useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { getProfiles } from "../../../../functions/api/resume/getProfiles";
import { useSelector } from "react-redux";
import TableSearchBar from "../commonComponents/TableSearchBar";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { toast } from "react-toastify";
import { getTagsConfig } from "../../../../functions/api/globalFunctions/getTagsConfig";
import { candidatesSearch } from "../../../../functions/api/employers/Search";
import { PoolData } from "../../../Ag Grid/PoolData";

import close from "../../../../assets/icons/closeIcon.png";
export default function RegisteredCandidates() {
  const [candidates, setCandidates] = useState([]);
  const [candidateTrigger, setCandidateTrigger] = useState(false);

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const [searchTag, setSearchTag] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [allCandidatesData, setAllCandidatesData] = useState([]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [allTags, setAllTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(0);

  const handleSearch = async () => {
    const searchKeys = ["firstName", "lastName", "email", "contact"];
    candidatesSearch(
      searchValue,
      setSearch,
      setFilteredData,
      setCandidates,
      setTotal,
      selectedTag,
      searchKeys,
      allCandidatesData,
      size
    );
  };

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && page < Math.ceil(+total / +size)) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const getAllCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode, 1, 1000000);
        if (res) {
          setAllCandidatesData(res?.data?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };
    getAllCandidates();
  }, []);

  useEffect(() => {
    const getCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode, page, size);
        if (res) {
          setCandidates(res?.data?.data);
          setTotal(res?.data?.total);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getCandidates();
  }, [candidateTrigger, page, size]);

  useEffect(() => {
    const getTags = async () => {
      try {
        const res = await getTagsConfig(accessToken, clientCode);
        if (res) {
          setAllTags(res?.data?.value?.split(","));
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg, 8000);
      }
    };

    getTags();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTagChange = (tag) => {
    setSelectedTag((prevTag) =>
      prevTag.includes(tag)
        ? prevTag.filter((t) => t !== tag)
        : [...prevTag, tag]
    );
  };

  const handleClear = () => {
    setSearchValue("");
    setSearch(false);
    setFilteredData([]);
    setSelectedTag("");
    setPage(1);
    setCandidates([]);
    setTotal(0);

    // Fetch the original data
    const getCandidates = async () => {
      try {
        const res = await getProfiles(accessToken, clientCode, 1, size);
        if (res) {
          setCandidates(res?.data?.data);
          setTotal(res?.data?.total);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    };

    getCandidates();
  };

  const clearAll = (e) => {
    setSelectedTag([]);
    e.stopPropagation();
  };

  return (
    <Content>
      <TableContainer component={Paper} className="tableBox">
        <div className="titleBox">
          <div className="titleContent">
            <span className="title">Candidates Pool</span>
            <span className="totalCandidates">Total Candidates: {total}</span>
          </div>
        </div>

        <SearchBarContainer>
          <TableSearchBar value={searchValue} setValue={setSearchValue} />
          <button className="applyButton" onClick={handleSearch}>
            Apply
          </button>
          <button className="clearButton" onClick={handleClear}>
            Clear
          </button>
          <TagDropdown ref={dropdownRef}>
            <DropdownToggle onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              Select Tags ({selectedTag.length})
              {selectedTag.length >= 1 && (
                <div className="clearBtn" onClick={clearAll}>
                  <img src={close} alt="close" />
                </div>
              )}
            </DropdownToggle>
            {isDropdownOpen && (
              <DropdownContentBox>
                <div class="inputBox">
                  <input
                    class="input"
                    type="search"
                    placeholder="Search tags"
                    value={searchTag}
                    onChange={(e) => setSearchTag(e.target.value)}
                  />
                </div>
                <DropdownContent>
                  {allTags
                    .sort((a, b) => {
                      const aSelected = selectedTag.includes(a);
                      const bSelected = selectedTag.includes(b);
                      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
                    })
                    .filter((val) => val.toLowerCase().includes(searchTag))
                    .map((tag, index) => (
                      <label key={index}>
                        <input
                          type="checkbox"
                          checked={selectedTag.includes(tag)}
                          onChange={() => handleTagChange(tag)}
                        />
                        {tag}
                      </label>
                    ))}
                </DropdownContent>
              </DropdownContentBox>
            )}
          </TagDropdown>
        </SearchBarContainer>

        <PoolData
          candidateTrigger={candidateTrigger}
          setCandidateTrigger={setCandidateTrigger}
          search={search}
          filteredData={filteredData}
          candidates={candidates}
          setFilteredData={setFilteredData}
          setSearch={setSearch}
          setCandidates={setCandidates}
          setTotal={setTotal}
          allCandidatesData={allCandidatesData}
          size={size}
        />

        {!search && (
          <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div>
        )}
      </TableContainer>
    </Content>
  );
}

const Content = styled.div`
  margin: 1rem 0% 2rem 0%;
  width: 98%;
  padding: 0 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative !important;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding-top: 1rem;

    .title {
      padding-left: 1.5rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .title2 {
      margin-left: 9rem;
      padding-left: 1.2rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .titleBox {
      width: 100%;
      padding: 0.5rem 0rem;
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }

    .titleContent {
      display: flex;
      // flex-direction: column;
      gap: 1rem;
    }

    .title {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .totalCandidates {
      padding-left: 53rem;
      font-size: 0.9rem;
      // margin-top: 0.5rem;
      font-weight: 600;
    }
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }

  .tableHead {
    background-color: #d1fff0;
    width: 100%;

    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
  }

  .tableBody {
    width: 100%;

    .tableCell {
      font-size: 0.73rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }

    .tagBox {
      display: flex;
      flex-flow: row wrap;
      column-gap: 0.25rem;
      row-gap: 0.25rem;
      align-items: center;
      justify-content: center;

      .tag {
        background-color: var(--cyan);
        margin: 0.25rem 0.25rem 0 0;
        white-space: nowrap;
        color: var(--white);
        font-weight: 500;
        padding: 0.2rem 0.4rem;
        border-radius: 0.2rem;
      }
    }
  }

  .checkBox {
    cursor: pointer;
  }
`;
const TagDropdown = styled.div`
  margin-left: 20rem;
  position: relative;
  display: inline-block;
  width: 230px;
`;

const DropdownToggle = styled.div`
  padding: 0.5rem 0rem;
  background-color: var(--white);
  border: 1px solid var(--cyan);
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: var(--font);
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  .clearBtn {
    // border: 1px solid var(--cyan);
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: var(--font);
    padding: 3px;
    border-radius: 5px;
    height: 15px;
    width: 15px;
    img {
      height: 12px;
      width: 12px;
      transition: all linear 2s;
    }
    &:hover {
      background: #584f4f2b;
      border-radius: 50%;
    }
  }
`;

const DropdownContentBox = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid var(--cyan);
  border-radius: 0.3rem;
  z-index: 1000;
  .inputBox {
    width: 100%;
    .input {
      border: none;
      width: 95%;
      padding: 0px 5px;
      height: 20px;
      border-bottom: 1px solid var(--cyan);
    }
    .input:focus {
      outline: none;
    }
  }

  .input::placeholder {
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
  }
`;
const DropdownContent = styled.div`
  background-color: var(--white);
  // border: 1px solid var(--cyan);
  // border-radius: 0.3rem;
  // padding: 0.5rem;
  max-height: 250px;
  overflow-y: auto;
  width: 230px;

  label {
    display: block;
    padding: 0.3rem 0;
    font-size: 0.8rem;
    font-family: var(--font);
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }

    input {
      // margin-right: 0.5rem;
    }
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;

  .applyButton {
    padding: 0.5rem 1rem;
    background-color: var(--cyan);
    color: white;
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 550;
    font-family: var(--font);
  }

  .clearButton {
    padding: 0.5rem 1rem;
    background-color: var(--white);
    color: var(--cyan);
    // color: black;
    border: 1px solid var(--cyan);
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 550;
    font-family: var(--font);
  }

  .clearButton:hover {
    background-color: #f4f4f4;
  }
`;
