import React from "react";
import styled from "styled-components";

const ReasoningDetails = ({ candidate }) => {
  const jobResumeRelevance = candidate?.scoreReasoning?.find(
    (item) => item.headerText === "Job & Resume Relevance"
  );

  const reasoningField = jobResumeRelevance?.fields?.find(
    (field) => field.label === "Reasoning"
  );

  const normalizeReasoningText = (text) => {
    return text.replace(/\n\n(\d+\.)/g, "\n$1").trim();
  };

  return (
    <Box>
      <div className="basicDetails">
        <div className="box1">
          <span className="jdId">
            Matched Resume for JD ID :{" "}
            {candidate?.jdId ? (
              <span className="value">{candidate?.jdId}</span>
            ) : (
              <span className="value">N/A</span>
            )}
          </span>
          <Line />
          <div className="candidateInfo">
            <div className="label labeltype">
              Candidate Name:&nbsp;
              {candidate?.name ? (
                <span className="value">{candidate?.name}</span>
              ) : (
                <span className="value">N/A</span>
              )}
            </div>
            <div className="label labeltype">
              AI Score :&nbsp;
              {candidate?.aiScore ? (
                <span className="value">{candidate?.aiScore}</span>
              ) : (
                <span className="value">0</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="details">
        <div className="box3">
          <div className="heading labeltype1">Score Reasoning&nbsp;</div>
          <Line />

          <div className="label1 labeltype2">
            <div className="value">
              {reasoningField?.value ? (
                <ul className="bullet-list">
                  {normalizeReasoningText(reasoningField.value)
                    .split("\n")
                    .map((line, index) => {
                      const cleanedLine = line.replace(/^\d+\.\s*/, "").trim();
                      return cleanedLine ? (
                        <li key={index}>{cleanedLine}</li>
                      ) : null;
                    })}
                </ul>
              ) : (
                <span>N/A</span>
              )}
            </div>{" "}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ReasoningDetails;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 2rem;
  margin: 2rem auto;

  .basicDetails {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #f4f4f4;
    border-radius: 0.75rem;
    padding: 1.5rem 2rem;

    .box1 {
      display: flex;
      flex-direction: column;

      // gap: 1rem;

      .jdId {
        font-size: 0.9rem;
        font-weight: 600;
        // text-align: center;

        .value {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
      .labeltype {
        margin-top: 1rem;
        gap: 1rem;
      }
      .candidateInfo {
        display: column;

        justify-content: space-between;

        .label {
          font-size: 0.9rem;
          font-weight: 600;

          .value {
            font-size: 0.9rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: 0.75rem;
    padding: 1.5rem 2rem;
    gap: 1.5rem;
  }

  .heading {
    font-size: 1rem;
    font-weight: 600;

    .value {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
  .label {
    font-size: 0.9rem;
    font-weight: 600;

    .value {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
  .labeltype {
    margin-top: 1rem;
    gap: 1rem;
  }
  .label1{
    margin-top: 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    

    .value {
      margin-top: 1.5rem;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 2rem;

      .bullet-list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;

        li {
          position: relative;
          padding-left: 1.5em;
          margin-bottom: 0.5em;
          line-height: 1.4;

          &::before {
            content: "•";
            position: absolute;
            left: 0;
            color: black; 
          }
  }
  .labeltype2 {
    margin-top: 0.7rem;
    gap: 1rem;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: #ccc;
  margin: 0.5rem 0rem;
`;
