import React, { useCallback, useEffect, useState } from "react";
import {
    ContactFilter,
    EmailFilter,
    NameFilter,
    YOEFilter,
  } from "./CustomFilter";
import { CandidatePoolGrid } from "./CandidatePoolGrid";
import ActionRenderer from "./ActionHandler";
import { dateConversion } from "../../utils/timeZoneConversation";

export const PoolData = ({
  candidateTrigger,
  setCandidateTrigger,
  filteredData,
  candidates,
  search,
  setFilteredData,
  setCandidates,
  setSearch,
  setTotal,
  allCandidatesData,
}) => {

    const [tableData, setTableData] = useState([]);

  const commonFilterParams = {
    setFilteredData,
    setCandidates,
    setSearch,
    setTotal,
    allCandidatesData,
  };
   
   const [colDefs] = useState([
     {
       field: "Name",
       filter: NameFilter,
       filterParams: commonFilterParams,
       flex: 1.5,
     },
     {
       field: "Email",
       filter: EmailFilter,
       filterParams: commonFilterParams,
       flex: 2,
     },
     {
       field: "Contact",
       flex: 1,
       filter: ContactFilter,
       filterParams: commonFilterParams,
       
     },
     { field: "Date of Reg", flex: 1 },
     {
       field: "YOE",
       filter: YOEFilter,
       filterParams: commonFilterParams,
       flex: 0.5,
     },
     { field: "Current Location", flex: 1 },
     {
       field: "Tags",
       filter: false,
       flex: 1.5,
       
     },
     {
       field: "Action",
       flex: 1,
       cellRenderer: ActionRenderer,
     },
   ]);
 
   // Transform row data function
   const transformData = (data) => {
     return data?.map((row, index) => ({
       key: index,
       Name: row?.firstName ? row?.firstName : "...",
       Email: row?.email ? row?.email : "...",
       Contact: row?.contact ? row?.contact : "...",
       "Date of Reg": row?.createdAt ? dateConversion(row?.createdAt) : "...",
       YOE: row?.yoe ? Math.floor(row.yoe) : "...",
       "Current Location": row?.currentLocation,
       Tags: row?.tags?.length !== 0 ? row?.tags?.split(",") : [],
       extraInfo: { row, index, candidateTrigger, setCandidateTrigger },
     }));
   };

   let dataToDisplay = candidates;
  useEffect(() => {
    dataToDisplay = search ? filteredData : candidates;
    const transformedData = transformData(dataToDisplay);
    setTableData(transformedData);
  }, [search, filteredData, candidates, candidateTrigger]);

  const onGridReady = useCallback((params) => {
    setTableData(candidates);
  }, []);



  return <>
  <CandidatePoolGrid tableData={tableData} colDefs={colDefs} onGridReady={onGridReady}/>
  </>;
};


